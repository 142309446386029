import { useContext,  useState} from "react";
import * as React from "react";
import {
    Card, CardBody, CardTitle, Divider, Flex, FlexItem, Icon,
    Level, LevelItem,
    Modal,
    ModalVariant, Nav, NavItem, NavList
} from "@patternfly/react-core";
 import {ImWhatsapp} from "react-icons/im";
 import {HiOutlineMail, HiPhone} from "react-icons/hi";
 import {GiSadCrab} from "react-icons/gi";

let WIDTH:string = "120";
let HEIGHT:string = "93.92";
let C_WIDTH:string = "560.66";
let C_HEIGHT:string="313.83";
let WD:string = "20";
export let LABEL_SHOW_DEBUG:string = "Show Debug";
export let LABEL_MOTTO:string = "Salty Sea Crab: Quality Auto Rentals";
export let UN_INITIALIZED:string = "false";
let dim="250";

export function Salty3() {
    let debugCount:number = 0;
    const [largeDisplayFlag, setLargeDisplayFlag] = useState(true);
    const [debugFlag, toggleDebugFlag] = useState(false);
    const [isRegistrationOpen,setIsRegistrationOpen] = useState(false);
    const [activeDebugTabKey, setActiveDebugTabKey] = useState(0);
    // const carAvailableContext = useContext(CarAvailabilityContext);
    const [selectedVehicle, setSelectedVehicle] = useState(-1);
    // Toggle currently active tab
    let handleTabClick = (event: any, tabIndex: React.SetStateAction<number>) => {
       setActiveDebugTabKey(tabIndex);
    };
    let count = () => {
        return debugCount;
    }
    const [availabilitiesFound,updateAvailabilitiesFound] = useState(UN_INITIALIZED);
    const [isModalProgressOpen, setIsModalProgressOpen] = React.useState(false);
    const [approvedRentalWindow,updateApprovedRentalWindow] = React.useState("");

    return (
        <>
             <div className="container-fluid">
                 <div className="row">
                     <div className="row pf-u-display-flex pf-u-justify-content-flex-start" style={{borderStyle: 'solid', borderColor: 'lightsteelblue', margin:'0', padding:'0'}}>
                         <div className="ws-example-flex-item"><img alt={LABEL_MOTTO} src="/SaltySeaCrab.png" width={WIDTH} height={HEIGHT}></img></div>
                         <div className="ws-example-flex-item"><Card isCompact isPlain>
                             <CardTitle>Salty Sea Crab</CardTitle>
                             <CardBody>"Quality Car Rentals"<br/>
                                 <HiPhone/><ImWhatsapp/> +1 242-812-7239<br/>
                                 <HiOutlineMail/> saltyseacrab.bahamas@gmail.com
                             </CardBody>
                         </Card></div>
                         <Flex direction={{ default: 'column' }} spaceItems={{ default: 'spaceItemsNone' }}>
                             <FlexItem align={{ default: 'alignRight' }} >
                             <a href="https://www.facebook.com/profile.php?id=100089265788362&sk=about" target="_blank" rel="noreferrer" color={"gray"}>
                             <Nav aria-label="Default global" ouiaId="DefaultNav" >
                                 <Divider
                                     orientation={{
                                         default: 'vertical'
                                     }}
                                 />
                                 <Icon size="md"><img width={WD} height={WD} src="/facebook-emoji.gif"></img></Icon>
                                 <Divider
                                     orientation={{
                                         default: 'vertical'
                                     }}
                                 />
                                 <Icon size="md"><GiSadCrab size={20}/></Icon>
                                 <Icon size="md"><img width={WD} height={WD} src="/facebook.png"/></Icon>
                             </Nav>
                             </a>
                             <a href="https://www.facebook.com/profile.php?id=100089265788362&sk=about" target="_blank" rel="noreferrer">
                                 Like Us on Facebook!
                             </a></FlexItem>
                         </Flex>
                     </div>
                 </div>
             </div>
            <div className="row">
                <div className="col-md-4" style={{borderStyle: 'solid', borderColor: 'lightsteelblue', margin:'0', padding:'0'}}>
                    <img alt={LABEL_MOTTO}
                         src="/toyota-noah.png" width={C_WIDTH} height={C_HEIGHT}></img>
                </div>
                <div className="col-md-3" style={{borderStyle: 'solid', borderColor: 'lightsteelblue', margin:'0', padding:'0'}}>
                </div>
                <div className="col-md-5" >
                </div>
            </div>
        </>
    )
}
